$(document).ready(function(){
	
	var bindOpenModal = function() {
		$(".open_modal").off("click");
		$(".open_modal").on("click", function(e){
			
			e.stopPropagation();
			e.preventDefault();
			
			var link = $(this);
			
			$.ajax({
			      type: 'GET',
			      url: link.attr("href"),
			      dataType: 'html',
			      success: function(data){
	
			    	  $("#myModal").html($(data));
			    	  $("#myModal").modal('show');
			    	  
			    	  bindOpenModal();
			    	  bindEvaluationForm();
			    	  bindEvaluationEdit();
			    	  
			    	  $('.date-picker').datepicker({
			    		  format: 'yyyy-mm-dd',
			    	  });
			    	  $('.date-picker').datepicker('setDate', new Date());
			    	  
			      },
			      error: function(jqXHR, textStatus, errorThrown) {
			    	  alert("Error " + textStatus);
			      }
			});
			
		});
	}
	
	var bindEvaluationEdit = function() {
		$(".open_modal2").off("click");
		$(".open_modal2").on("click", function(e){
			
			e.stopPropagation();
			e.preventDefault();
			
			var link = $(this);
			
			$.ajax({
			      type: 'GET',
			      url: link.attr("href"),
			      dataType: 'html',
			      success: function(data){

			    	  $("#myModal").html($(data));
			    	  $("#myModal").modal('show');
			    	  
			    	  bindOpenModal();
			    	  bindEvaluationForm();
			    	  
			    	  $('.date-picker').datepicker({
			    		  format: 'yyyy-mm-dd',
			    	  });
			    	  $('.date-picker').datepicker('setDate', new Date());
			    	  
			      },
			      error: function(jqXHR, textStatus, errorThrown) {
			    	  alert("Error " + textStatus);
			      }
			});
			
		});
	}
	
	var bindEvaluationForm = function() {
		$(".evaluation_form").off("submit");
		$(".evaluation_form").on("submit", function(e){
			
			e.stopPropagation();
			e.preventDefault();
			
			var form = $(this);
			
			$.ajax({
				type: 'POST',
				url: form.attr("action"),
				data: form.serialize(),
				dataType: 'html',
				success: function(data){
			    	  
					$("#myModal").html($(data));
					$("#myModal").modal('show');
					
					bindOpenModal();
					bindEvaluationForm();
					bindEvaluationEdit();
					
					$('.nav-tabs a[aria-controls=evaluations]').tab('show');
					
					$('.date-picker').datepicker({
						format: 'yyyy-mm-dd',
					});
					$('.date-picker').datepicker('setDate', new Date());

				},
				error: function(jqXHR, textStatus, errorThrown) {
					alert("Error " + textStatus + " > " + jqXHR.responseText);
			    }
			});
			
		});
	}
	
	bindOpenModal();
	bindEvaluationEdit();
	
	tinymce.init({
		selector: '.tinymce_textarea',
		plugins : 'autolink link lists charmap preview',
		menu: {
			edit: { title: 'Edit', items: 'undo redo  | cut copy paste selectall | searchreplace' },
			insert: { title: 'Insert', items: 'link charmap' },
			format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | removeformat' },
		},
		toolbar: 'undo redo | bold italic underline | link hr | alignleft aligncenter alignright | blockquote bullist numlist outdent indent | code',
	});
	
		
});